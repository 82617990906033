<!--家庭教育视频管理-->
<template>
    <div>
        <a-row class="searchBox flex flex-jc-sb">
            <a-space class="flex flex-jc-sb">
                <a-input-search v-model:value="param.name" placeholder="请输入视频名称" style="width: 300px" @search="handleSelectChange" />
                <a-select ref="select" v-model:value="param.cate_home_id" style="width: 200px" allowClear placeholder="家庭教育类别" @change="handleSelectChange">
                    <a-select-option v-for="(item,idx) in Education" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                </a-select>
            </a-space>
            <a-button type="primary" @click="handleEdit()">
                <PlusCircleOutlined />添加视频
            </a-button>
        </a-row>

        <a-row class="tableBox">
            <a-table :dataSource="list" :columns="columns" rowKey="id" :pagination="false" :scroll="{x: 1000, y: 'calc(100vh - 390px)' }">
                <template #number="{text, record, index}">
                    <span>{{ (param.curr - 1) * param.row + index + 1 }}</span>
                </template>
                <template #cover="{text, record, index}">
                    <a-image :width="60" :src="domain+text" :fallback="domain+'/default.png'" style="border-radius:4px" />
                </template>
                <template #operation="{ record }">
                    <a-space>
                        <a-button type="primary" @click="handleEdit(record)">编辑</a-button>
                        <a-button type="primary" @click="handleDelete(record)">删除</a-button>
                    </a-space>
                </template>
            </a-table>
            <div class="flex flex-jc-fe Pageing">
                <TablePagination :curr="param.curr" :row="param.row" :count="count" @changeTableRequest="changeTableRequest" />
            </div>
        </a-row>

        <VideoEdit ref="VideoEditRef" cateId="4" @success="getList"></VideoEdit>
    </div>
</template>
<script>
import { defineComponent, reactive, toRefs, ref, getCurrentInstance, onMounted } from 'vue';
import TablePagination from '@/components/TablePagination';
import useDelete from '@/hooks/useDelete.js';
import { PlusCircleOutlined } from '@ant-design/icons-vue';
import VideoEdit from '@/components/VideoEdit';


export default defineComponent({
    components: {
        TablePagination,
        PlusCircleOutlined,
        VideoEdit
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const { useDeleteFun } = useDelete();
        const VideoEditRef = ref();
        onMounted(() => {
            EducationType();
            getList();
        });

        let initData = reactive({
            list: [],
            Education: [],
            columns: [
                { title: '序号', dataIndex: 'number', width: 80, slots: { customRender: 'number' } },
                { title: '视频封面', dataIndex: 'cover', slots: { customRender: 'cover' } },
                { title: '视频标题', dataIndex: 'name' },
                { title: '分类', dataIndex: 'cateHome.name' },
                { title: '观看量', dataIndex: 'view_count_sum' },
                { title: '点赞量', dataIndex: 'like_count_sum' },
                { title: '录入时间', dataIndex: 'create_time' },
                { title: '操作', fixed: 'right', width: 180, slots: { customRender: 'operation' } },
            ],
            param: {
                curr: 1,
                row: 10,
                cate_id: 4
            },
            count: 0,
            pages: 1,
            domain: proxy.domain
        });
        const EducationType = () => {
            proxy.http.post('/admin.cateHome/index').then(response => {
                initData.Education = response;
                VideoEditRef.value.Education = response;
            })
        }
        //视频列表
        const getList = () => {
            proxy.http.post('/admin.video/index', initData.param).then(response => {
                initData.list = response.list;
                initData.count = response.count;
                initData.pages = response.pages;
            })
        };

        const changeTableRequest = (event, value) => {
            if (event == 'curr') {
                initData.param.curr = value;
            } else {
                initData.param.curr = 1;
                initData.param.row = value;
            }
            getList();
        };

        //新建弹框
        const handleEdit = (row) => {
            let { name, like_count_set, view_count_set, url, cover, content, cate_home_id } = row ? row : initData.param;
            VideoEditRef.value.title = row ? '编辑家庭教育视频' : '添加家庭教育视频';
            VideoEditRef.value.form = row ? {
                id: row.id,
                name,
                cate_id: 4,
                cate_home_id,
                like_count_set,
                view_count_set,
                url,
                cover,
                content
            } : {
                cate_id: 4,
                cate_home_id,
            };
            VideoEditRef.value.getToken();
            VideoEditRef.value.handleDrawer(true);
        };

        //删除
        const handleDelete = (row) => {
            useDeleteFun('/admin.video/delete', [row.id]).then(() => {
                getList();
            })
        };

        //下拉筛选
        const handleSelectChange = () => {
            initData.param.curr = 1;
            initData.list = [];
            getList();
        };

        return {
            ...toRefs(initData),
            getList,
            EducationType,
            VideoEditRef,
            changeTableRequest,
            handleEdit,
            handleDelete,
            handleSelectChange
        }
    }
})
</script>
